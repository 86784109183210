<template>
  <span class="time-show-text">{{ time }}</span>
</template>
<script>
import { parseTime } from '@/utils/index'

export default {
  name: 'TimeShow',
  data() {
    return {
      time: '',
      interval: ''
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.getTime()
    }, 1000)
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    getTime() {
      const date = new Date()
      this.time = parseTime(date, '{h}:{i}:{s}')
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
