<template>
  <div class="home-container">
    <!-- <div class="home-text">欢迎进入{{ title }}</div> -->
    <svg-icon icon-class="office" class="home-bg-svg"></svg-icon>
    <time-show class="home-time-show" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import defaultSettings from '@/settings'
import TimeShow from '@/components/TimeShow'

export default {
  name: 'Home',
  components: {
    TimeShow
  },
  data() {
    return {
      title: defaultSettings.title
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  }
}
</script>

<style lang="scss" scoped>
.home {
    &-container {
        height: 100%;
    }
    &-text {
        font-size: 30px;
        line-height: 46px;
        text-align: center;
    }
    &-bg-svg{
        font-size: 500px;
        margin: 200px auto 0;
        display: block;
    }
    &-time-show{
        position: absolute;
        top: 290px;
        left: 50%;
        font-size: 36px;
        margin-left: -40px;
        color: #87DBFF;
    }
}
</style>
